<template>
  <div class="lesson-content">
    <div class="lesson-content-header">
      <SwitchButtons
        class="content-header-switch"
        v-model="switchValue"
        :items="tabsItems"
      />
      <div class="d-flex align-center" v-if="item.type == 'article'">
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentArticle.addBlock('title')"
        >
          <v-icon small> mdi-format-title </v-icon>
          <span>Заголовок</span>
        </v-btn>
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentArticle.addBlock('text')"
        >
          <v-icon small> mdi-format-letter-case </v-icon>
          <span>Текст</span>
        </v-btn>
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentArticle.addBlock('image')"
        >
          <v-icon small> mdi-image-outline </v-icon>
          <span>Изображение</span>
        </v-btn>
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentArticle.addBlock('video')"
        >
          <v-icon small> mdi-play-box </v-icon>
          <span> Видео </span>
        </v-btn>
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentArticle.addBlock('audio')"
        >
          <v-icon small> mdi-music-note </v-icon>
          <span> Аудио </span>
        </v-btn>
      </div>
      <div class="d-flex align-center" v-if="item.type == 'cards'">
        <v-btn
          depressed
          :disabled="send"
          class="button-white-blue mr-4"
          @click="$refs.lessonContentCard.addBlock()"
        >
          <v-icon small> mdi-plus-box-multiple-outline </v-icon>
          <span>Добавить карточку</span>
        </v-btn>
      </div>
    </div>

    <div class="lesson-content-blocks">
      <LessonContentArticle
        v-if="item.type == 'article'"
        :isDrag="switchValue"
        ref="lessonContentArticle"
        @loadingItems="loadingItems"
      />
      <LessonContentCard
        v-else-if="item.type == 'cards'"
        :isDrag="switchValue"
        @loadingItems="loadingItems"
        ref="lessonContentCard"
      />
    </div>
    <div class="lesson-content-footer">
      <v-btn
        depressed
        :disabled="send"
        class="button-white-black mr-4"
        @click="resetValues"
      >
        Отменить изменения
      </v-btn>
      <v-btn depressed :disabled="send" class="button-blue" @click="sendValues">
        Cохранить изменения
      </v-btn>
    </div>
  </div>
</template>
<script>
import LessonContentCard from "@/views/Lessons/LessonContentCard.vue";
import LessonContentArticle from "@/views/Lessons/LessonContentArticle.vue";
import SwitchButtons from "@/components/main/SwitchButtons";
export default {
  components: {
    LessonContentCard,
    LessonContentArticle,
    SwitchButtons,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    send: true,
    tabsItems: [
      { text: "Контент", value: false },
      { text: "Порядок", value: true },
    ],
    switchValue: false,
    blocks: [],
  }),
  methods: {
    sendValues() {
      if (this.item.type == "article") {
        this.$refs.lessonContentArticle.sendValues();
      } else if (this.item.type == "cards") {
        this.$refs.lessonContentCard.sendValues();
      }
    },
    resetValues() {
      if (this.item.type == "article") {
        this.$refs.lessonContentArticle.getBlocks();
      } else if (this.item.type == "cards") {
        this.$refs.lessonContentCard.getBlocks();
      }
    },
    loadingItems(bool) {
      this.send = bool;
    },
  },

  created() {
    // this.getAllValues();
  },
};
</script>
<style lang="scss" scoped>
.lesson-content {
  height: calc(100vh - 203px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .lesson-content-header {
    height: 64px;
    display: flex;
    align-items: center;
    background: var(--app-grey-1);
    padding: 0px 64px;
    .content-header-switch {
      margin-right: 64px;
    }
  }
  .lesson-content-blocks {
    overflow: auto;
    height: calc(100% - 128px);
    padding: 8px 0px;
  }
  .lesson-content-footer {
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    align-items: center;
    padding: 0px 64px;
  }
}
</style>
