<template>
  <div class="page-container" v-if="loading">
    <div class="breadcrumbs container-breadcrumbs">
      <div class="breadcrumbs-item" @click="goToCourses">Курсы</div>
      <div class="breadcrumbs-line"></div>
      <div class="breadcrumbs-item" @click="goToCourse">{{ course.name }}</div>
      <div class="breadcrumbs-line"></div>
      <div class="breadcrumbs-item">
        {{
          item.type == "video"
            ? "Видео"
            : item.type == "cards"
              ? "Карточки"
              : item.type == "article"
                ? "Статья"
                : item.type == "audio"
                  ? "Аудио"
                  : ""
        }}: {{ item.name }}
      </div>
    </div>
    <div class="container-main-block" :style="
      tab === 'information' || tab === 'homework'
        ? `height: calc(100vh - 145px);`
        : 'height: calc(100vh - 82px);'
    ">
      <div class="main-block-title pl-16 pr-16">
        {{ item.name }}
      </div>
      <v-tabs class="pl-16 pr-16" v-model="tab" background-color="var(--app-grey-1)" color="var(--app-blue)">
        <v-tabs-slider color="var(--app-blue)"></v-tabs-slider>
        <v-tab :href="'#information'">
          <v-icon class="mr-2" size="18">mdi-information</v-icon>
          <div class="tab-text">Информация</div>
        </v-tab>
        <v-tab :href="'#content'" v-if="item.type != 'video' && item.type != 'audio'" @click="getValuesContent">
          <v-icon class="mr-2" size="18">mdi-view-split-vertical</v-icon>
          <div class="tab-text">Содержание</div>
        </v-tab>
        <v-tab :href="'#homework'">
          <v-icon class="mr-2" size="18">mdi-bag-personal</v-icon>
          <div class="tab-text">Домашние задание</div>
        </v-tab>
      </v-tabs>
      <v-divider style="margin: 0 64px"></v-divider>
      <div class="main-block-items">
        <v-tabs-items v-model="tab">
          <v-tab-item value="information">
            <LessonInformation ref="lessonInformation" :item="item" :tests="tests" :statistics="statistics"
              @updateInfo="updateInfo" />
          </v-tab-item>
          <v-tab-item value="content">
            <LessonContent :item="item" />
          </v-tab-item>
          <v-tab-item value="homework">
            <LessonHomeWork ref="lessonHomeWork" :item="item"></LessonHomeWork>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="container-bottom-block" v-if="tab === 'information' || tab === 'homework'">
      <div>
        <v-btn depressed :disabled="send" @click="getItem(true)" class="button-white-black mr-4">
          Отменить изменения
        </v-btn>
        <v-btn depressed :loading="send" class="button-blue" @click="sendItem">
          Cохранить изменения
        </v-btn>
      </div>
      <div v-if="tab === 'information'">
        <v-btn depressed :disabled="send" class="button-white-red" @click="dialogDelete = true">
          Удалить урок
        </v-btn>
      </div>
    </div>

    <DialogConfirm v-model="dialogDelete" titleText="Удаление урока" :mainText="`Вы уверены что хотите удалить  ${item ? `${item.name}` : 'урок'
    }?`" @click:delete="deleteItem">
    </DialogConfirm>
  </div>
</template>
<script>
import DialogConfirm from "@/components/main/dialog/DialogConfirm";
import LessonInformation from "@/views/Lessons/LessonInformation";
import LessonContent from "@/views/Lessons/LessonContent";
import LessonHomeWork from "@/views/Lessons/LessonHomeWork";

export default {
  components: {
    DialogConfirm,
    LessonInformation,
    LessonContent,
    LessonHomeWork,
  },
  data: () => ({
    item: {},
    course: {},
    tags: [],
    tests: [],
    statistics: {},
    loading: false,
    tab: 0,
    dialogDelete: false,
    send: false,
  }),
  methods: {
    async getItem(homework) {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/lesson", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.item = res.data;
          if (homework) {
            this.item.homeworkDescription ? this.item.homeworkDescription : this.item.homeworkDescription = ''
          }
          this.getCourse();
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getCourse() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/course", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.item.courseId,
          },
        })
        .then((res) => {
          this.course = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getStatistics() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/lesson/statistics", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.statistics = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    // async getTags() {
    //   await this.$axios
    //     .get(this.$store.getters.apiAdminPanelV4 + "/tag/list", {
    //       headers: {
    //         authorization: this.$store.getters.adminPanelHeaderAuth,
    //       },
    //     })
    //     .then((res) => {
    //       this.tags = res.data;
    //     })
    //     .catch((err) => {
    //       this.$store.commit("errorSet", true);
    //       this.$store.commit("errorTextSet", err);
    //     });
    // },
    async getTests() {
      await this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/test/list",
          {},
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.tests = res.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getAllValues() {
      Promise.all([this.getItem(), this.getTests()]).then(() => {
        this.loading = true;
      });
    },
    async sendItem() {
      let item = null
      if (this.tab == 'information') {
        item = this.$refs.lessonInformation.sendItem();
      }
      else if (this.tab == 'homework') {
        item = this.$refs.lessonHomeWork.sendItem();
      }
      if (item) {
        if (typeof item.photo == "object") {
          await this.getInformationImage(item.photo).then((res) => {
            item.infoPhoto = res;
          });
        }
        this.send = true;
        let formData = new FormData();
        if(item.homeworkFiles && item.homeworkFiles.length > 0){
          item.homeworkFiles.forEach((element) => {
            formData.append("homeworkFile", element);
          });
        }
        formData.append("courseId", this.item.courseId);
        formData.append("id", item.id);
        formData.append("name", item.name);
        formData.append("type", item.type);
        formData.append("description", item.description || "");
        formData.append("photo", item.photo);
        formData.append("video", item.video || "");
        formData.append("audio", item.audio || "");
        formData.append("key", item.key || "");
        formData.append("isVisible", item.isVisible);
        formData.append("infoPhoto", JSON.stringify(item.infoPhoto));
        formData.append("infoVideo", JSON.stringify(item.infoVideo));
        if (item.testId) {
          formData.append("testId", item.testId);
        }
        formData.append("homeworkTitle", item.homeworkTitle || "");
        formData.append("homeworkDescription", item.homeworkDescription || "");
        this.$axios
          .post(this.$store.getters.apiAdminPanelV4 + "/lesson", formData, {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          })
          .then(() => {
            this.$store.commit("successSet", true);
            this.$store.commit(
              "successTextSet",
              "Измененения успешно сохранены"
            );
            this.send = false;
            this.getAllValues();
          })
          .catch((err) => {
            this.send = false;
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      }
    },
    updateInfo(item) {
      this.item = item;
    },

    deleteItem() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/lesson", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then(() => {
          this.$router.push({ name: "Course", params: { id: this.course.id } });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getValuesContent() {
      if (this.$refs.courseContent) {
        this.$refs.courseContent.getItems();
      }
    },
    goToCourse() {
      this.$router.push({ name: "Course", params: { id: this.course.id } });
    },
    goToCourses() {
      this.$router.push({ name: "Courses" });
    },
  },
  created() {
    this.getAllValues();
    this.getStatistics();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .container-breadcrumbs {
    margin: 40px 64px 20px 64px;
  }

  .container-main-block {
    display: flex;
    flex-direction: column;

    .main-block-title {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 28px;
      font-weight: 600;
      color: var(--app-black);
    }

    .main-block-items {
      height: 100%;
      overflow: auto;
    }
  }

  .container-bottom-block {
    min-height: 64px;
    max-height: 64px;
    background: var(--app-grey-2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 64px;
  }
}
</style>
