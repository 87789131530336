<template>
  <div v-if="loading">
    <draggable
      :list="blocks"
      tag="div"
      :draggable="isDrag ? `.lesson-block-item` : ''"
    >
      <div class="lesson-block-item" v-for="item in blocks" :key="item.newId">
        <div v-if="isDrag" class="block-item-icon">
          <v-icon color="--app-grey-3">mdi-menu</v-icon>
        </div>
        <div v-else class="block-item-noticon"></div>
        <div class="block-item-box" v-if="item.type == 'title'">
          <div class="item-box-title">
            <div class="title-text-block">Заголовок</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <TextInput
            :disabled="isDrag"
            v-model="item.text"
            label="Текст заголовка"
          />
        </div>
        <div class="block-item-box" v-else-if="item.type == 'text'">
          <div class="item-box-title">
            <div class="title-text-block">Текстовый блок</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <trumbowyg
            :disabled="isDrag"
            :config="config"
            v-model="item.text"
          ></trumbowyg>
          <div class="d-flex flex-row justify-space-between">
            <v-btn
              @click="clearTags(['p', 'br', 'ol', 'ul', 'li', 'a'], item)"
              class="button-grey-blue mt-3 mr-5"
              :disabled="isDrag"
              depressed
            >
              <span>Очистить стили текста</span>
            </v-btn>
          </div>
        </div>
        <div class="block-item-box" v-else-if="item.type == 'image'">
          <div class="item-box-title">
            <div class="title-text-block">Изображение</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <ImageInput
            :disabled="isDrag"
            label="Файл изображения"
            :showImg="false"
            mdiIcon="mdi-image-outline"
            bottomImg
            v-model="item.file"
          />
        </div>
        <div class="block-item-box" v-else-if="item.type == 'audio'">
          <div class="item-box-title">
            <div class="title-text-block">Аудио</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <div>
            <FileInput
              label="Файл аудио"
              acceptFiles="audio/*"
              v-model="item.file"
            />
            <audio v-if="item.file" controls :src="srcItem(item.file)"></audio>
          </div>
        </div>
        <div class="block-item-box" v-else-if="item.type == 'video'">
          <div class="item-box-title">
            <div class="title-text-block">Видео</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <div>
            <FileInput
              label="Файл видео"
              acceptFiles="video/*"
              v-model="item.file"
            />
            <div>
              <v-btn
                :disabled="!item.file"
                @click="dialogVideo = true"
                class="button-grey-blue"
                depressed
              >
                Посмотреть
              </v-btn>
            </div>
            <DialogVideo :src="srcItem(item.file)" v-model="dialogVideo" />
          </div>
        </div>
      </div>
    </draggable>
  </div>
  <div v-else class="loading-block">
    <v-progress-circular
      indeterminate
      :size="60"
      :width="4"
      color="var(--app-blue)"
    ></v-progress-circular>
  </div>
</template>
<script>
import TextInput from "@/components/main/inputs/TextInput.vue";
import ImageInput from "@/components/main/inputs/ImageInput.vue";
import FileInput from "@/components/main/inputs/FileInput.vue";
import DialogVideo from "@/components/main/dialog/DialogVideo";
import draggable from "vuedraggable";
export default {
  components: {
    TextInput,
    ImageInput,
    FileInput,
    DialogVideo,
    draggable,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    isDrag: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    blocks: [],
    config: {
      lang: "ru",
      btns: [
        ["strong", "em", "del"],
        ["unorderedList", "orderedList"],
        ["link"],
      ],
    },
    loading: false,
    dialogVideo: false,
  }),
  computed: {},
  methods: {
    srcItem(value) {
      if (typeof value == "string") {
        return value;
      } else if (value && typeof value == "object") {
        return URL.createObjectURL(value);
      }
    },
    clearTags(tagEnter, item) {
      let tags = [],
        tag = "";
      for (let a = 0; a < tagEnter.length; a++) {
        tag = tagEnter[a].replace(/<|>/g, "").trim();
        if (tagEnter[a] == "br") tags.push(tag);
        else if (tagEnter[a].length > 0) tags.push(tag, "/" + tag);
      }
      let re = new RegExp("<(?!(" + tags.join("|") + ")s*/?)[^>]+>", "g"); //remove all tags кроме заданных в tagEnter
      item.text = item.text.replace(re, "");
      item.text = item.text.replace(/\s*style=(["'])(.*?)\1/gim, ""); //remove styles
      item.text = item.text.replace(/\s*class=(["'])(.*?)\1/gim, "");
    },
    async getBlocks() {
      await this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/article/block/list",
          {
            lessonId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.blocks = res.data.map((i) => {
            i.newId = (~~(Math.random() * 1e8)).toString(16);
            return i;
          });
          this.loading = true;
          this.$emit("loadingItems", false);
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    deleteBlock(item) {
      this.blocks = this.blocks.filter((i) => i.newId != item.newId);
    },
    addBlock(type) {
      if (type == "title") {
        this.blocks.push({
          type: "title",
          text: "",
          newId: (~~(Math.random() * 1e8)).toString(16),
          sort: this.blocks.length + 1,
        });
      } else if (type == "text") {
        this.blocks.push({
          type: "text",
          text: "",
          newId: (~~(Math.random() * 1e8)).toString(16),
          sort: this.blocks.length + 1,
        });
      } else if (type == "image") {
        this.blocks.push({
          type: "image",
          text: null,
          file: null,
          newId: (~~(Math.random() * 1e8)).toString(16),
          sort: this.blocks.length + 1,
        });
      } else if (type == "audio") {
        this.blocks.push({
          type: "audio",
          file: null,
          newId: (~~(Math.random() * 1e8)).toString(16),
          sort: this.blocks.length + 1,
        });
      } else if (type == "video") {
        this.blocks.push({
          type: "video",
          file: null,
          newId: (~~(Math.random() * 1e8)).toString(16),
          sort: this.blocks.length + 1,
        });
      }
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    async saveImage(el, lessonId, type) {
      let formData = new FormData();
      formData.append("lessonId", lessonId);
      formData.append("file", el.file);
      formData.append("fileType", type);

      await this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/article/file", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          el.linkFile = res.data.file;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    async sendValues() {
      this.$emit("loadingItems", true);
      this.loading = false;
      this.blocks = this.blocks
        .map((v) => {
          if (v.type === "title" || v.type === "text") {
            if (v.text) {
              return v;
            }
          }
          if (v.type === "image" || v.type === "audio" || v.type === "video") {
            if (v.file) {
              return v;
            }
          }
        })
        .filter((v) => v);
      for (const el of this.blocks) {
        if (el.type == "image" && el.file && typeof el.file == "object") {
          await this.saveImage(el, this.$route.params.id, "image");
          await this.getInformationImage(el.file).then((result) => {
            el.infoFile = result;
          });
          delete el.file;
        }
        if (el.type == "audio" && el.file && typeof el.file == "object") {
          await this.saveImage(el, this.$route.params.id, "audio");
          delete el.file;
        }
        if (el.type == "video" && el.file && typeof el.file == "object") {
          await this.saveImage(el, this.$route.params.id, "video");
          delete el.file;
        }
      }
      this.blocks = this.blocks.map((i, index) => {
        i.sort = index + 1;
        if (i.linkFile) {
          i.file = i.linkFile;
          delete i.linkFile;
          delete i.newId;
        }
        return i;
      });

      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/article",
          {
            lessonId: this.$route.params.id,
            blocks: this.blocks,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getBlocks();
          this.$store.commit("successSet", true);
          this.$store.commit("successTextSet", "Блоки успешно сохранены");
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async scrollToBottom() {
      let slide = document.querySelector(".lesson-content-blocks");
      let count = 100;
      let startTop = slide.scrollHeight;
      for (let i = 0; i < count; i++) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500 / count);
        });
        slide.scrollTop += startTop / count;
      }
    },
  },
  created() {
    this.getBlocks();
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.lesson-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 50%;
  min-width: 550px;
  .block-item-icon {
    margin: 0px 16px 0px 24px;
    cursor: pointer;
  }
  .block-item-noticon {
    min-width: 64px;
    max-width: 64px;
  }
  .block-item-box {
    min-width: 700px;
    max-width: 700px;
    background: var(--app-white);
    padding: 32px 40px;
    border-radius: 10px;

    .item-box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      user-select: none;
      .title-text-block {
        color: var(--app-black);
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
}
.loading-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
