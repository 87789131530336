<template>
  <div v-if="loading">
    <div class="setting-block-statistics" v-if="statistics">
      <div class="statistics-block">
        <div class="statistics-block-title">Кол-во пользователей</div>
        <div class="statistics-block-value">
          {{ statistics.assignmentCount }}
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Просмотры</div>
        <div class="statistics-block-value">
          {{ statistics.countedViews }} ({{ statistics.countedViewsPercent }}%)
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Не смотрели урок</div>
        <div class="statistics-block-value">
          {{ statistics.countedNotViews }} ({{
            statistics.countedNotViewsPercent
          }}%)
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Результат тестирования</div>
        <div class="statistics-block-value" v-if="statistics.averagePercent">
          {{ statistics.averagePercent }}%
        </div>
        <div class="statistics-block-value" v-else>0%</div>
      </div>
    </div>
    <div class="block-lessons-lesson-setting">
      <div class="setting-block">
        <div class="setting-block-box">
          <div class="block-box-title">
            <div class="box-title-text">Информация</div>
          </div>
          <v-form ref="formInformation" class="block-box-lessons">
            <ImageInput v-model="lesson.photo" label="Обложка курса" valid />
            <TextInput v-model="lesson.name" label="Название курса" valid />
            <TextArea v-model="lesson.description" label="О курсе" />
            <VideoInput
              v-if="lesson.type == 'video'"
              v-model="lesson.video"
              label="Видео"
            />

            <FileInput
              v-if="lesson.type == 'audio'"
              label="Файл аудио"
              acceptFiles="audio/*"
              v-model="lesson.audio"
              valid
            />
            <audio
              class="mb-4"
              v-if="lesson.audio"
              controls
              :src="srcItem(lesson.audio)"
            ></audio>
            <TextInput v-model="lesson.key" label="URL-ключ" />
            <SwitchInput
              icon="mdi-eye-outline"
              label="Видимость"
              v-model="lesson.isVisible"
            />
           
          </v-form>
        </div>
      </div>
      <div class="setting-block">
        <div class="setting-block-box">
          <div class="block-box-title">
            <div class="box-title-text">Данные</div>
          </div>
          <div class="block-box-lessons">
            <div class="box-lessons-info">
              <div class="info-title">ID урока</div>
              <div class="info-value">
                {{ lesson.id }}
              </div>
            </div>
            <div class="box-lessons-info">
              <div class="info-title">Дата создания</div>
              <div class="info-value">
                {{ lesson.dateCreated | moment("DD.MM.YYYY") }}
              </div>
            </div>
            <div class="box-lessons-info">
              <div class="info-title">Дата последнего редактирования</div>
              <div class="info-value">
                {{ lesson.dateUpdated | moment("DD.MM.YYYY") }}
              </div>
            </div>
          </div>
        </div>
        <div class="setting-block-box">
          <div class="block-box-title">
            <div class="box-title-text">Тестирование</div>
            <v-btn
              depressed
              class="button-white-blue"
              @click="dialogAddTest = true"
              :disabled="!!lesson.testId"
            >
              Добавить тест
            </v-btn>
          </div>
          <div class="block-box-lessons">
            <div class="box-items-list-item" v-if="!!lesson.testId">
              <div class="list-item-text">
                <span>
                  {{ newTest.name }}
                </span>
              </div>
              <div @click="deleteTest" class="list-item-icon">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
          </div>
          <Dialog
            v-model="dialogAddTest"
            refForm="testForm"
            titleText="Добавление теста курсу"
            @click:success="addTest"
            :buttonsFooter="{
              success: {
                title: 'Добавить тест',
                loading: true,
              },
            }"
          >
            <template v-slot:blockMain>
              <div style="height: 300px">
                <Table
                  @click:row="selectedTest"
                  :items="testsList"
                  headerFixed
                  :headers="testsHeaders"
                >
                  <template v-slot:[`item.selected`]="{ item }">
                    <CheckBox v-model="item.selected" color="var(--app-blue)" />
                  </template>
                </Table>
              </div>
            </template>
            <template v-slot:buttonSucces>
              <v-btn
                depressed
                class="button-blue"
                @click="addTest"
                :disabled="!newTest.id"
              >
                Добавить тест
              </v-btn>
            </template>
          </Dialog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageInput from "@/components/main/inputs/ImageInput";
import TextInput from "@/components/main/inputs/TextInput";
import TextArea from "@/components/main/inputs/TextArea";
import SwitchInput from "@/components/main/inputs/SwitchInput";
import CheckBox from "@/components/main/inputs/CheckBox";
import VideoInput from "@/components/main/inputs/VideoInput";

export default {
  components: {
    ImageInput,
    TextInput,
    TextArea,
    SwitchInput,
    CheckBox,
    VideoInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    tests: {
      type: Array,
      default() {
        return [];
      },
    },
    statistics: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    lesson: {},
    testsList: [],
    dialogAddTest: false,
    newTest: {},
    testsHeaders: [
      {
        text: "",
        align: "left",
        sortable: false,
        value: "selected",
      },
      {
        text: "Название",
        align: "left",
        sortable: false,
        value: "name",
      },
    ],
  }),
  methods: {
    openDialogTag() {
      this.dialogAddTag = true;
    },
    srcItem(value) {
      if (typeof value == "string") {
        return value;
      } else if (value && typeof value == "object") {
        return URL.createObjectURL(value);
      }
    },

    updateItem() {
      this.lesson = JSON.parse(JSON.stringify(this.item));

      this.testsList = this.tests.map((i) => {
        i.selected = false;
        return { ...i };
      });
      if (this.lesson.testId) {
        this.newTest = JSON.parse(JSON.stringify(this.lesson.test));
        // this.lesson.finalTest = this.lesson.finalTest.id;
      }

      this.loading = true;
    },
    sendItem() {
      if (this.$refs.formInformation.validate()) {
        return this.lesson;
      }
    },
    addTest() {
      this.lesson.testId = this.newTest.id;
      this.testsList.forEach((element) => {
        element.selected = false;
      });
     
      this.dialogAddTest = false;
    },
    deleteTest() {
      this.newTest = {};
      this.lesson.testId = null;
    },
    selectedTest(item) {
      item.selected = !item.selected;
      if (item.selected) {
        this.newTest = { ...item };
        this.testsList.forEach((element) => {
          if (element.id != item.id) {
            element.selected = false;
          }
        });
      } else {
        this.newTest = {};
        this.testsList.forEach((element) => {
          element.selected = false;
        });
      }
    },
 
  },
  mounted() {},
  created() {
    this.updateItem();
  },
  beforeDestroy() {},
  watch: {
    item() {
      this.updateItem();
    },
  },
};
</script>
<style lang="scss" scoped>
.block-lessons-lesson-setting {
  background: var(--app-grey-1);
  //   overflow: auto;
  height: 100%;
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }
      .block-box-lessons {
        .box-items-list-item {
          width: 100%;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: var(--app-white);
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 16px 12px;
          .list-item-text {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: var(--app-black);
          }
          .list-item-icon {
            cursor: pointer;
          }
        }
        .box-lessons-info {
          margin-bottom: 24px;
          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }
          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }
  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }
  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
  .dialog-tag-table {
    height: 400px;
  }
}
.setting-block-statistics {
  display: flex;
  justify-content: space-between;
  padding: 32px 64px;

  .statistics-block {
    padding: 24px;
    background: var(--app-white);
    border-radius: 6px;
    margin: 0px 8px;
    width: 100%;
    // min-width: 385px;
    .statistics-block-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--app-black);
      margin-bottom: 4px;
    }
    .statistics-block-value {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .statistics-block:first-child {
    margin-left: 0px;
  }
  .statistics-block:last-child {
    margin-right: 0px;
  }
}
</style>
