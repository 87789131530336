<template>
  <div v-if="loading">
    <draggable
      :list="blocks"
      tag="div"
      :draggable="isDrag ? `.lesson-block-item` : ''"
    >
      <div class="lesson-block-item" v-for="item in blocks" :key="item.newId">
        <div v-if="isDrag" class="block-item-icon">
          <v-icon color="--app-grey-3">mdi-menu</v-icon>
        </div>
        <div v-else class="block-item-noticon"></div>
        <div class="block-item-box">
          <div class="item-box-title">
            <div class="title-text-block">Изображение</div>
            <div @click="deleteBlock(item)" style="cursor: pointer">
              <v-icon color="var(--app-grey-3)">mdi-delete-outline</v-icon>
            </div>
          </div>
          <ImageInput
            ref="file"
            :disabled="isDrag"
            label="Файл изображения"
            :showImg="false"
            mdiIcon="mdi-image-outline"
            bottomImg
            v-model="item.image"
          />
          <div class="item-box-title">
            <div class="title-text-block">Заголовок</div>
            <div class="title-subtext-block">
              Символов: {{ item.title.length }}/ 60
            </div>
          </div>
          <TextInput
            :disabled="isDrag"
            v-model="item.title"
            :maxLength="60"
            label="Текст заголовка"
          />

          <div class="item-box-title">
            <div class="title-text-block">Текстовый блок</div>
            <div class="title-subtext-block" :style="filterTagsForStyle(item)">
              Символов: {{ filterTags(item.text).length }}/{{ item.maxText }}
            </div>
          </div>

          <trumbowyg
            ref="trumbowyg"
            :disabled="isDrag"
            :config="config"
            v-model="item.text"
          ></trumbowyg>
          <div class="d-flex flex-row justify-space-between">
            <v-btn
              @click="clearTags(['p', 'br', 'ol', 'ul', 'li', 'a'])"
              class="button-grey-blue mt-3 mr-5"
              :disabled="isDrag"
              depressed
            >
              <span>Очистить стили текста</span>
            </v-btn>
          </div>
        </div>
      </div>
    </draggable>
  </div>
  <div v-else class="loading-block">
    <v-progress-circular
      indeterminate
      :size="60"
      :width="4"
      color="var(--app-blue)"
    ></v-progress-circular>
  </div>
</template>
<script>
import TextInput from "@/components/main/inputs/TextInput.vue";
import ImageInput from "@/components/main/inputs/ImageInput.vue";
import draggable from "vuedraggable";
export default {
  components: {
    TextInput,
    ImageInput,
    draggable,
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    isDrag: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data: () => ({
    blocks: [],
    config: {
      lang: "ru",
      btns: [
        ["strong", "em", "del"],
        ["unorderedList", "orderedList"],
        ["link"],
      ],
    },
    loading: false,
  }),
  computed: {},
  methods: {
    clearTags(tagEnter, item) {
      let tags = [],
        tag = "";
      for (let a = 0; a < tagEnter.length; a++) {
        tag = tagEnter[a].replace(/<|>/g, "").trim();
        if (tagEnter[a] == "br") tags.push(tag);
        else if (tagEnter[a].length > 0) tags.push(tag, "/" + tag);
      }
      let re = new RegExp("<(?!(" + tags.join("|") + ")s*/?)[^>]+>", "g"); //remove all tags кроме заданных в tagEnter
      item.text = item.text.replace(re, "");
      item.text = item.text.replace(/\s*style=(["'])(.*?)\1/gim, ""); //remove styles
      item.text = item.text.replace(/\s*class=(["'])(.*?)\1/gim, "");
    },
    filterTagsForStyle(item) {
      if (item.text.replace(/(<([^>]+)>)/gi, "").length > item.maxText) {
        item.errorBlock = true;
        return "color:red;font-weight:600;";
      } else {
        item.errorBlock = false;
      }
    },
    filterTags(string) {
      return string.replace(/<[^>]+>|&nbsp|&lt|&gt/gi, "");
    },
    async getBlocks() {
      await this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/cards/list",
          {
            lessonId: this.$route.params.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then((res) => {
          this.blocks = res.data.map((i) => {
            i.newId = (~~(Math.random() * 1e8)).toString(16);
            i.maxText =
              i.title && i.image
                ? 210
                : !i.title && i.image
                ? 330
                : i.title && !i.image
                ? 480
                : !i.title && !i.image
                ? 600
                : 0;
            i.errorBlock = false;
            return i;
          });
          this.loading = true;
          this.$emit("loadingItems", false);
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    checkMaxText() {
      let err = false;
      this.blocks.forEach((i) => {
        i.maxText =
          i.title && i.image
            ? 210
            : !i.title && i.image
            ? 330
            : i.title && !i.image
            ? 480
            : !i.title && !i.image
            ? 600
            : 0;
        if (i.errorBlock) {
          err = true;
        }
      });
      this.$emit("loadingItems", err);
    },
    deleteBlock(item) {
      this.blocks = this.blocks.filter((i) => i.newId != item.newId);
    },
    addBlock() {
      this.blocks.push({
        image: null,
        text: "",
        title: "",
        newId: (~~(Math.random() * 1e8)).toString(16),
        sort: this.blocks.length + 1,
      });
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    },
    async saveImage(el, lessonId) {
      let formData = new FormData();
      formData.append("lessonId", lessonId);
      formData.append("file", el.image);

      await this.$axios
        .post(this.$store.getters.apiAdminPanelV4 + "/cards/file", formData, {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .then((res) => {
          el.linkImage = res.data.file;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },

    async sendValues() {
      this.$emit("loadingItems", true);
      this.loading = false;
      this.blocks = this.blocks
        .map((v) => {
          if (!v.title && !v.text && !v.image) {
            return "";
          } else {
            return v;
          }
        })
        .filter((v) => v);
      for (const el of this.blocks) {
        if (el.image && typeof el.image == "object") {
          await this.saveImage(el, this.$route.params.id);
          await this.getInformationImage(el.image).then((result) => {
            el.infoImage = result;
          });
          delete el.image;
        }
      }
      this.blocks = this.blocks.map((i, index) => {
        i.sort = index + 1;
        return i;
      });
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/cards",
          {
            lessonId: this.$route.params.id,
            cards: this.blocks,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.getBlocks();
          this.$store.commit("successSet", true);
          this.$store.commit("successTextSet", "Блоки успешно сохранены");
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async scrollToBottom() {
      let slide = document.querySelector(".lesson-content-blocks");
      let count = 100;
      let startTop = slide.scrollHeight;
      for (let i = 0; i < count; i++) {
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 500 / count);
        });
        slide.scrollTop += startTop / count;
      }
    },
  },
  created() {
    this.getBlocks();
  },
  watch: {
    blocks: {
      handler() {
        this.checkMaxText();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.lesson-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: 50%;
  min-width: 550px;
  .block-item-icon {
    margin: 0px 16px 0px 24px;
    cursor: pointer;
  }
  .block-item-noticon {
    min-width: 64px;
    max-width: 64px;
  }
  .block-item-box {
    min-width: 700px;
    max-width: 700px;
    background: var(--app-white);
    padding: 32px 40px;
    border-radius: 10px;

    .item-box-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      user-select: none;
      .title-text-block {
        color: var(--app-black);
        font-size: 22px;
        font-weight: 600;
      }
      .title-subtext-block {
        color: var(--app-grey-3);
        font-size: 14px;
      }
    }
  }
}
.loading-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>